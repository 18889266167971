



















import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'description-nft-info': () => import('@/modules/mint/components/description/description-nft-info.vue'),
    'mint-road-map': () => import('@/modules/mint/components/description/mint-road-map.vue'),
  },
})
export default class extends Vue {
  tab = 'sale-roadmap'
}
